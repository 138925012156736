import React from 'react';
import { Row, Col, Pagination, Table} from 'antd';
import { withTranslation } from 'react-i18next';

class StudsList extends React.Component{
    state = {view: 'Standar', currentPage: this.props.defPage }

    paginate = (pageNum) => {
        this.setState({currentPage: pageNum});
        this.props.onPageSelect(pageNum);            
    }

    changeView = (v) =>{
        this.setState({view:v.target.value});
    }

    render(){
        const { t } = this.props;
        const columns = [
            {
              title: t("name"),
              dataIndex: 'name',
              render: (text, record) => ( <a href={'/horse/ped/'+record.id}>{text}</a> ),
            },
            {
                title: t("family"),
                dataIndex: 'family',
            },
            {
                title: t("country"),
                dataIndex: 'country',
            },
            {
                title: t("year"),
                dataIndex: 'year',
            },
            {
                title: t("paddock"),
                dataIndex: 'paddock',
                render: (text, record) => { 
                    return (record.paddock) ? record.paddock.name : ''
            },
            },
          ];
        return(
            <div>
                <Table columns={columns} dataSource={this.props.data} pagination={false} />
                <Row className="bottomBar">
                    <Col xs={{ span: 4 }} md={{ span: 6 }} lg={{ span: 8 }}>
                    </Col>
                    <Col xs={{ span: 16 }} md={{ span: 12 }} lg={{ span: 8 }} className="text_center">
                        <Pagination size="small" current={this.state.currentPage} total={this.props.regCount}
                        onChange={this.paginate} showSizeChanger={false} defaultPageSize={20} />
                    </Col>
                    <Col xs={{ span: 4 }} md={{ span: 6 }} lg={{ span: 8 }} className="text_right">
                        {t("registers")}: {this.props.regCount}</Col>
                </Row>
            </div>
          );
    }
}

export default withTranslation()(StudsList);