import React from 'react';
import axios from 'axios';
import { Row, Col, Form, Input, Button, Select} from 'antd';
import Spinner from '../components/spinner';
import HorsesList from '../components/HorsesList';
import FamilyFilter from '../components/FamilyFilter';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

class Seacrh extends React.Component{
    state = {
        horses: [],
        loading: false,
        regCount:0,
        famOptions: [],
        compOptions: [],
        fname: '',
        ffamily: '',
        fcompetition: '',
    }

    componentDidMount() {           
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token 03cf971bb813dfe8d93d5682cbc034c527041f77"
        }

        axios.get('/api/competition/combo/list/').then(
            res => {
                this.setState({compOptions: res.data})
            }
        )
    }

    onFinish = values => {
        this.setState({fname: values.filter_name, fcompetition: values.filter_competition});
        this.paginate(1);
    };

    paginate = pageNum => {
        this.setState({loading: true});
        const url = `/api/horse/list/search/?page=${pageNum}&filter_name=${this.state.fname}&filter_family=${this.state.ffamily}&filter_competition=${this.state.fcompetition}&filter_country=`
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token 03cf971bb813dfe8d93d5682cbc034c527041f77"
        }
        axios.get(url)
            .then(
                res => {
                    this.setState({
                        horses: res.data.results,
                        loading: false,
                        regCount: res.data.count
                    });
                }
            )
    }

    handleFamilyFilter = (family) => {
        this.setState({ffamily: family});
    }

    render(){
        const { t } = this.props;
        const renderCompetitions = this.state.compOptions.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.id}>{data.name}</Option>;
        });

        return(
            <div className="layout_padding">
                <Form name="filter_form" onFinish={this.onFinish}>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} className="filter_field">
                            <Form.Item name="filter_name">
                                <Input placeholder={t("name")} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} className="filter_field">
                            <Form.Item name="filter_family">
                                <FamilyFilter onSelectFamily={this.handleFamilyFilter} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} className="filter_field">
                            <Form.Item name="filter_competition">
                                <Select
                                    showSearch allowClear placeholder={t("competition")} optionFilterProp="children"
                                >
                                    {renderCompetitions}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col xs={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} className="filter_field">
                            <Form.Item name="filter_year">
                                <RangePicker picker="year" />
                            </Form.Item>
                        </Col> */}
                        <Col xs={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} className="text_center">
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    {this.state.loading ? <Spinner /> : t("search")}
                                </Button>  
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <Spinner spinning={this.state.loading}>
                    <HorsesList data={this.state.horses} regCount={this.state.regCount}
                        onPageSelect={this.paginate} defPage={3} />
                </Spinner>
            </div>
        );
    }
}

export default withTranslation()(Seacrh);