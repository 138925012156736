import React from 'react';
import axios from 'axios';
import { Row, Col, Form, Button} from 'antd';
import Spinner from '../components/spinner';
import FamilyFilter from '../components/FamilyFilter';
import { Bar, Line } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { withTranslation } from 'react-i18next';

class Statistics extends React.Component{
    state = {
        familyBreed: [], familyBreedMother: [], loading: false, ffamily: '', winsFamily: [], champions: 0,
        loadingChampions: false, raceWon: 0, loadingWon: false, championsP: 0, loadingChampionsP: false,
        winsYear: [], loadingWonByYear: false, champsYear: [], loadingChampsByYear: false, search: false,
        colors: ['#41a9bd', '#502878', 'blue', 'red', 'orange', 'yellow', 'green', 'purple', 'cyan', 'pink', 'brown', 'black'],
    }

    componentDidMount() {           
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token 03cf971bb813dfe8d93d5682cbc034c527041f77"
        }
    }

    onFinish = values => {
        this.setState({loading: true, search: false});
        this.champions();
        this.raceWon();
        this.championsP();
        this.winsByYear();
        this.champsByYear();
        this.familyBreed();
        this.familyBreedMother();
        this.winsByFamily();
    };

    champions(){
        this.setState({loadingChampions: true});
        axios.get(`/api/statistics/championsByFamily/${this.state.ffamily}`)
            .then(
                res => {
                    this.setState({ champions : res.data });
                    this.setState({ loadingChampions: false });
                }
            )
    }

    raceWon(){
        this.setState({loadingWon: true});
        axios.get(`/api/statistics/wonByFamily/${this.state.ffamily}`)
            .then(
                res => {
                    this.setState({ raceWon : res.data });
                    this.setState({ loadingWon: false });
                }
            )
    }
    
    championsP(){
        this.setState({loadingChampionsP: true});
        axios.get(`/api/statistics/championsPByFamily/${this.state.ffamily}`)
            .then(
                res => {
                    this.setState({ championsP : res.data });
                    this.setState({ loadingChampionsP: false });
                }
            )
    }

    familyBreed(){
        axios.get(`/api/statistics/crossFamilyBreed/${this.state.ffamily}`)
            .then(
                res => {
                    let ds = [];
                    let lb = [];
                    res.data.forEach(d => {
                        ds.push(d.total);
                        lb.push(d.father__family);
                    });

                    this.setState({familyBreed : {
                        datasets: [{ label: "Champions", data: ds, backgroundColor: 'blue' }],
                        labels: lb
                    }});
                    this.setState({ loading: false });
                }
            )
    }

    familyBreedMother(){
        axios.get(`/api/statistics/crossFamilyBreedMother/${this.state.ffamily}`)
            .then(
                res => {
                    let ds = [];
                    let lb = [];
                    res.data.forEach(d => {
                        ds.push(d.total);
                        lb.push(d.family);
                    });

                    this.setState({familyBreedMother : {
                        datasets: [{ label: "Champions", data: ds, backgroundColor: 'orange' }],
                        labels: lb
                    }});
                    this.setState({ loading: false });
                }
            )
    }

    winsByFamily(){
        axios.get(`/api/statistics/winsByFamily/${this.state.ffamily}`)
            .then(
                res => {
                    let ds = [];
                    let lb = [];
                    res.data.forEach(d => {
                        ds.push(d.total);
                        lb.push(d.comp_name);
                    });

                    this.setState({winsFamily : {
                        datasets: [{ data: ds, backgroundColor: 'green' }],
                        labels: lb
                    }});
                    this.setState({ loading: false });
                }
            )
    }

    winsByYear(){
        this.setState({ loadingWonByYear: true });
        axios.get(`/api/statistics/wonByYear/${this.state.ffamily}`)
            .then(
                res => {
                    let ds = [];
                    let lb = [];
                    res.data.forEach(d => {
                        ds.push(d.total);
                        lb.push(d.comp_year.split('-')[0]);
                    });

                    this.setState({winsYear : {
                        datasets: [{ data: ds, backgroundColor: '#41a9bd' }],
                        labels: lb
                    }});
                    this.setState({ loadingWonByYear: false });
                }
            )
    }

    champsByYear(){
        this.setState({ loadingChampsByYear: true });
        axios.get(`/api/statistics/championsByYear/${this.state.ffamily}`)
            .then(
                res => {
                    let ds = [];
                    let lb = [];
                    res.data.forEach(d => {
                        ds.push(d.total);
                        lb.push(d.comp_year.split('-')[0]);
                    });

                    this.setState({champsYear : {
                        datasets: [{ data: ds, backgroundColor: '#502878' }],
                        labels: lb
                    }});
                    this.setState({ loadingChampsByYear: false });
                }
            )
    }
    

    handleFamilyFilter = (family) => {
        if(family){
            this.setState({ffamily: family, search: true});
        }
    }

    render(){
        const { t } = this.props;
        return(
            <div className="layout_padding">
                {/* { localStorage.getItem('token') == null ? this.props.history.push('/login') : ''} */}
                <Form name="filter_form" onFinish={this.onFinish}>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 20 }} lg={{ span: 20 }} className="filter_field">
                            <Form.Item name="filter_family">
                                <FamilyFilter onSelectFamily={this.handleFamilyFilter} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} className="text_center">
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" disabled={!this.state.search}>
                                    {this.state.loading ? <Spinner /> : t("search")}
                                </Button>  
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                        <center><Spinner spinning={this.state.loadingChampions}>
                            <div className="statistic_card">
                                <h3 className="title_light">{t("#OfChampions")}</h3>
                                <h1 className="ind_light">{this.state.champions}</h1>
                            </div>
                        </Spinner></center>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                        <center><Spinner spinning={this.state.loadingWon}>
                            <div className="statistic_card">
                                <h3 className="title_light">{t("#OfRacesWon")}</h3>
                                <h1 className="ind_light">{this.state.raceWon}</h1>
                            </div>
                        </Spinner></center>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                        <center><Spinner spinning={this.state.loadingChampionsP}>
                            <div className="statistic_card">
                                <h3 className="title_light">{t("%OfChampions")}</h3>
                                <h1 className="ind_light">{this.state.championsP} %</h1>
                            </div>
                        </Spinner></center>
                    </Col>
                </Row>
                <Spinner spinning={this.state.loading}CrossFamilyBreed>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                            <h3 className="title_dark">{t("top10SireFamilyCombination")}</h3>
                            <Bar data={this.state.familyBreed} options={{ legend: { display: false }, plugins: {
                            datalabels: { display: false, //   color: 'white'
                            }}, scales: {
                                yAxes: [{
                                  ticks: {
                                    beginAtZero: true
                                  }
                                }]
                              } }} />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                            <h3 className="title_dark">{t("top10DamFamilyCombination")}</h3>
                            <Bar data={this.state.familyBreedMother} options={{ legend: { display: false }, plugins: {
                            datalabels: { display: false, //   color: 'white'
                            }}, scales: {
                                yAxes: [{
                                  ticks: {
                                    beginAtZero: true
                                  }
                                }]
                              } }} />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                            <h3 className="title_dark">{t("top10RacesWon")}</h3>
                            <Bar data={this.state.winsFamily} options={{ legend: { display: false }, plugins: {
                            datalabels: { display: false, //   color: 'white'
                            }}, scales: {
                                yAxes: [{
                                  ticks: {
                                    beginAtZero: true
                                  }
                                }]
                              } }} />
                        </Col>
                    </Row>
                </Spinner>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <center><Spinner spinning={this.state.loadingWonByYear}>
                        <h3 className="title_dark">{t("#WinsByYear")}</h3>
                        <Line data={this.state.winsYear} options={{ legend: { display: false }, plugins: {
                        datalabels: { display: false, 
                        }}, scales: {
                            yAxes: [{
                                ticks: {
                                beginAtZero: true
                                }
                            }]
                            } }} />
                        </Spinner></center>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <center><Spinner spinning={this.state.loadingChampsByYear}>
                        <h3 className="title_dark">{t("#ChampionsByYear")}</h3>
                        <Line data={this.state.champsYear} options={{ legend: { display: false }, plugins: {
                        datalabels: { display: false, 
                        }}, scales: {
                            yAxes: [{
                                ticks: {
                                beginAtZero: true
                                }
                            }]
                            } }} />
                        </Spinner></center>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withTranslation()(Statistics);