import React from 'react';
import axios from 'axios';
import { Row, Col, Form, Input, Button, Table} from 'antd';
import Spinner from '../components/spinner';
import { withTranslation } from 'react-i18next';

class Names extends React.Component{
    state = {
        names: [], fname: '',
        loading: false,
        regCount:0,
    }

    componentDidMount() {           
        this.paginate(1);
    }

    onFinish = values => {
        this.setState({fname: values.filter_name});
        this.paginate(1);
    };

    paginate = pageNum => {
        this.setState({loading: true});
        const url = `/api/names/?page=${pageNum}&filter_name=${this.state.fname}`
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token 03cf971bb813dfe8d93d5682cbc034c527041f77"
        }
        axios.get(url)
            .then(
                res => {
                    this.setState({
                        names: res.data,
                        loading: false,
                        regCount: res.data.count
                    });
                }
            )
    }

    render(){
        const { t } = this.props;
        const columns = [
            {
              title: t("name"),
              dataIndex: 'name',
            },
          ];

        return(
            <div className="layout_padding">
                <Form name="filter_form" onFinish={this.onFinish}>
                    <Row>
                        <Col xs={{ span: 20 }} md={{ span: 20 }} lg={{ span: 20 }} className="filter_field">
                            <Form.Item name="filter_name">
                                <Input placeholder={t("name")} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }} className="text_center">
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    {this.state.loading ? <Spinner /> : t("search")}
                                </Button>  
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Spinner spinning={this.state.loading}>
                    <Table columns={columns} dataSource={this.state.names} pagination={true} />
                </Spinner>
            </div>
        );
    }
}

export default withTranslation()(Names);