import React from 'react';
import { Select, Card } from 'antd';
import { t } from 'i18next';

const { Meta } = Card;

class HorseCardFilter extends React.Component{
    state = { horseOptions: [], }

    familyChange = (family) => {
        this.props.onSelectFamily(family);            
    }

    horseChange = (horse) => {
        this.props.onSelectHorse(horse);            
    }

    render(){
        return(
            <Card {...this.props} actions={[<div><Select showSearch placeholder={t("family")} optionFilterProp="children"
                                    onChange={this.familyChange} allowClear>
                                    {this.props.families}
                                    </Select></div>,]}>
                <Meta
                    title= {<center><Select showSearch placeholder={t("horse")} optionFilterProp="children"
                        onChange={this.horseChange} className="full_width" allowClear>
                        {this.props.horses}
                    </Select></center>}
                />
            </Card>
          );
    }
}

export default HorseCardFilter;