import React from 'react';
import axios from 'axios';
import { Card, Rate } from 'antd';
import HorseCard from '../components/HorseCard';
import Spinner from '../components/spinner';
import '../css/tree.css';
import { isMobile } from "react-device-detect";
import { withTranslation } from 'react-i18next';

const { Meta } = Card; 

class HorsePedigree extends React.Component{
    state = {
        horse: [],
        horseId: this.props.match.params.horseId,
        loading: true, stars: 0
    }

    componentDidMount(){
        const horseId = this.props.match.params.horseId;
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token 03cf971bb813dfe8d93d5682cbc034c527041f77"
        }
        axios.get(`/api/horse/horsePed/${horseId}/`)
            .then(
                res => {
                    this.setState({
                        horse: res.data,
                        loading: false
                    });
                });
        
        axios.get(`/api/horse/value/${horseId}/`).then(
            res => {
                this.setState({stars: res.data.results[0].starsC});
            });
    }

    render(){
        const { t } = this.props;
        var horses = this.state.horse;
        var horse = horses[0];
        if(horses[1]){
            var father = horses[1].find((h)=> h.id === horse.father_id ? h : null);
            var mother = horses[1].find((h)=> h.id === horse.mother_id ? h : null);
        }
        if(horses[2]){
            var ffather = horses[2].find((h)=> h.id === father.father_id ? h : null);
            var fmother = horses[2].find((h)=> h.id === father.mother_id ? h : null);
            var mfather = horses[2].find((h)=> h.id === mother.father_id ? h : null);
            var mmother = horses[2].find((h)=> h.id === mother.mother_id ? h : null);
        }
        if(horses[3]){
            var gf1father = ffather ? horses[3].find((h)=> h.id === ffather.father_id ? h : null) : null;
            var gf1mother = ffather ? horses[3].find((h)=> h.id === ffather.mother_id ? h : null) : null;
            var gm1father = fmother ? horses[3].find((h)=> h.id === fmother.father_id ? h : null) : null;
            var gm1mother = fmother ? horses[3].find((h)=> h.id === fmother.mother_id ? h : null) : null;
            var gf2father = mfather ? horses[3].find((h)=> h.id === mfather.father_id ? h : null) : null;
            var gf2mother = mfather ? horses[3].find((h)=> h.id === mfather.mother_id ? h : null) : null;
            var gm2father = mmother ? horses[3].find((h)=> h.id === mmother.father_id ? h : null) : null;
            var gm2mother = mmother ? horses[3].find((h)=> h.id === mmother.mother_id ? h : null) : null;
        }
        if(horses[4]){
            var gf3father = gf1father ? horses[4].find((h)=> h.id === gf1father.father_id ? h : null) : null;
            var gf3mother = gf1father ? horses[4].find((h)=> h.id === gf1father.mother_id ? h : null) : null;
            var gm3father = gf1mother ? horses[4].find((h)=> h.id === gf1mother.father_id ? h : null) : null;
            var gm3mother = gf1mother ? horses[4].find((h)=> h.id === gf1mother.mother_id ? h : null) : null;
            var gf4father = gm1father ? horses[4].find((h)=> h.id === gm1father.father_id ? h : null) : null;
            var gf4mother = gm1father ? horses[4].find((h)=> h.id === gm1father.mother_id ? h : null) : null;
            var gm4father = gm1mother ? horses[4].find((h)=> h.id === gm1mother.father_id ? h : null) : null;
            var gm4mother = gm1mother ? horses[4].find((h)=> h.id === gm1mother.mother_id ? h : null) : null;
            var gf5father = gf2father ? horses[4].find((h)=> h.id === gf2father.father_id ? h : null) : null;
            var gf5mother = gf2father ? horses[4].find((h)=> h.id === gf2father.mother_id ? h : null) : null;
            var gm5father = gf2mother ? horses[4].find((h)=> h.id === gf2mother.father_id ? h : null) : null;
            var gm5mother = gf2mother ? horses[4].find((h)=> h.id === gf2mother.mother_id ? h : null) : null;
            var gf6father = gm2father ? horses[4].find((h)=> h.id === gm2father.father_id ? h : null) : null;
            var gf6mother = gm2father ? horses[4].find((h)=> h.id === gm2father.mother_id ? h : null) : null;
            var gm6father = gm2mother ? horses[4].find((h)=> h.id === gm2mother.father_id ? h : null) : null;
            var gm6mother = gm2mother ? horses[4].find((h)=> h.id === gm2mother.mother_id ? h : null) : null;
        }
        
        return(
            <div>
                <style dangerouslySetInnerHTML={{__html: isMobile ? '#root { width: fit-content }' : '' }} />
                <Spinner spinning={this.state.loading}>
                    <table width="100%" cellPadding="5px">
                        <tr>
                            <th width='20%'>{t("generations")}</th>
                            <th width='20%'>{t("1st")}</th>
                            <th width='20%'>{t("2nd")}</th>
                            <th width='20%'>{t("3rd")}</th>
                            <th width='20%'>{t("4th")}</th>
                        </tr>
                        <tr>
                            <td rowSpan="16" className="text_center">
                                {horse ? <HorseCard data={horse} /> : '' }
                                <Rate disabled value={parseInt(this.state.stars)} />
                            </td>
                            <td rowSpan="8">
                                {father ? <HorseCard data={father} /> : ''}
                            </td>
                            <td rowSpan="4">{ffather ? <HorseCard data={ffather} /> : '' }</td>
                            <td rowSpan="2">{gf1father  ? <HorseCard data={gf1father} /> : '' }</td>
                            <td>
                                <Card className="male_card">
                                    <Meta
                                        title= {gf3father  ? <center><a href={'/horse/ped/'+gf3father.id}>{gf3father.name}</a></center> : '' }
                                    />
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Card className="female_card">
                                    <Meta
                                        title= {gf3mother  ? <center><a href={'/horse/ped/'+gf3mother.id}>{gf3mother.name}</a></center> : '' }
                                    />
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan="2">{gf1mother ? <HorseCard data={gf1mother} /> : ''}</td>
                            <td>
                                <Card className="male_card">
                                    <Meta
                                        title= {gm3father ? <center><a href={'/horse/ped/'+gm3father.id}>{gm3father.name}</a></center> : ''}
                                    />
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Card className="female_card">
                                    <Meta
                                        title= {gm3mother ? <center><a href={'/horse/ped/'+gm3mother.id}>{gm3mother.name}</a></center> : ''}
                                    />
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan="4">{fmother ? <HorseCard data={fmother} /> : '' }</td>
                            <td rowSpan="2">{gm1father ? <HorseCard data={gm1father} /> : '' }</td>
                            <td>
                                <Card className="male_card">
                                    <Meta
                                        title= {gf4father ? <center><a href={'/horse/ped/'+gf4father.id}>{gf4father.name}</a></center> : '' }
                                    />
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Card className="female_card">
                                    <Meta
                                        title= {gf4mother ? <center><a href={'/horse/ped/'+gf4mother.id}>{gf4mother.name}</a></center> : '' }
                                    />
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan="2">{gm1mother ? <HorseCard data={gm1mother} /> : ''}</td>
                            <td>
                                <Card className="male_card">
                                    <Meta
                                        title= {gm4father ? <center><a href={'/horse/ped/'+gm4father.id}>{gm4father.name}</a></center> : ''}
                                    />
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Card className="female_card">
                                    <Meta
                                        title= {gm4mother ? <center><a href={'/horse/ped/'+gm4mother.id}>{gm4mother.name}</a></center> : ''}
                                    />
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan="8">{mother ? <HorseCard data={mother} /> : ''}</td>
                            <td rowSpan="4">{mfather ? <HorseCard data={mfather} /> : '' }</td>
                            <td rowSpan="2">{gf2father ? <HorseCard data={gf2father} /> : ''}</td>
                            <td>
                                <Card className="male_card">
                                    <Meta
                                        title= {gf5father ? <center><a href={'/horse/ped/'+gf5father.id}>{gf5father.name}</a></center> : ''}
                                    />
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Card className="female_card">
                                    <Meta
                                        title= {gf5mother ? <center><a href={'/horse/ped/'+gf5mother.id}>{gf5mother.name}</a></center> : ''}
                                    />
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan="2">{gf2mother ? <HorseCard data={gf2mother} /> : ''}</td>
                            <td>
                                <Card className="male_card">
                                    <Meta
                                        title= {gm5father ? <center><a href={'/horse/ped/'+gm5father.id}>{gm5father.name}</a></center> : ''}
                                    />
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Card className="female_card">
                                    <Meta
                                        title= {gm5mother ? <center><a href={'/horse/ped/'+gm5mother.id}>{gm5mother.name}</a></center> : ''}
                                    />
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan="4">{mmother ? <HorseCard data={mmother} /> : '' }</td>
                            <td rowSpan="2">{gm2father ? <HorseCard data={gm2father} /> : '' }</td>
                            <td>
                                <Card className="male_card">
                                    <Meta
                                        title= {gf6father ? <center><a href={'/horse/ped/'+gf6father.id}>{gf6father.name}</a></center> : '' }
                                    />
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Card className="female_card">
                                    <Meta
                                        title= {gf6mother ? <center><a href={'/horse/ped/'+gf6mother.id}>{gf6mother.name}</a></center> : '' }
                                    />
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan="2">{gm2mother ? <HorseCard data={gm2mother} /> : '' }</td>
                            <td>
                                <Card className="male_card">
                                    <Meta
                                        title= {gm6father ? <center><a href={'/horse/ped/'+gm6father.id}>{gm6father.name}</a></center> : '' }
                                    />
                                </Card>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Card className="female_card">
                                    <Meta
                                        title= {gm6mother ? <center><a href={'/horse/ped/'+gm6mother.id}>{gm6mother.name}</a></center> : '' }
                                    />
                                </Card>
                            </td>
                        </tr>
                    </table>
                </Spinner>
            </div>
        );
    }
}

export default withTranslation()(HorsePedigree);