import React from 'react';
import axios from 'axios';
import { Form, Button, Card, Select} from 'antd';
import Spinner from '../components/spinner';
import HorsesList from '../components/HorsesList';
import HorseCardFilter from '../components/HorseCardFilter';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

class SearchAdvance extends React.Component{
    state = {
        horses: [], horseOptions: [], horseMOptions: [], famOptions: [], loading: false,
        ffamily: '', mfamily: '', father: '',mother: '', fffamily: '', mffamily: '', ffather: '',mfather: '',
        regCount:0, 
    }

    componentDidMount(){
        // if(localStorage.getItem('token') != null){
            this.setState({loading: true});
            // axios.defaults.headers = {
            //     "Content-Type": "application/json",
            //     Authorization: "Token "+localStorage.getItem('token')
            // }
            axios.get('/api/horse/family/list/').then(
                    res => {
                        this.setState({famOptions: res.data})
                    }
                )
            axios.get(`/api/horse/horses/list/?sex=h`).then(
                    res => {
                        this.setState({horseOptions: res.data});
                    }
                )
            axios.get(`/api/horse/horses/list/?sex=m`).then(
                    res => {
                        this.setState({horseMOptions: res.data, loading: false});
                    }
                )
        // }
    }

    onFinish = values => {
        this.paginate(1);
    };

    paginate = pageNum => {
        this.setState({loading: true});
        let filters = '';
        filters += (this.state.ffamily) ? `&ffamily=${this.state.ffamily}` : '';
        filters += (this.state.mfamily) ? `&mfamily=${this.state.mfamily}` : '';
        filters += (this.state.father) ? `&father=${this.state.father}` : '';
        filters += (this.state.mother) ? `&mother=${this.state.mother}` : '';
        filters += (this.state.fffamily) ? `&fffamily=${this.state.fffamily}` : '';
        filters += (this.state.mffamily) ? `&mffamily=${this.state.mffamily}` : '';
        filters += (this.state.ffather) ? `&ffather=${this.state.ffather}` : '';
        filters += (this.state.mfather) ? `&mfather=${this.state.mfather}` : '';
        filters += (this.state.gg1father) ? `&gg1father=${this.state.gg1father}` : '';
        filters += (this.state.gg1fatherF) ? `&gg1fatherF=${this.state.gg1fatherF}` : '';
        filters += (this.state.gg2father) ? `&gg2father=${this.state.gg2father}` : '';
        filters += (this.state.gg2fatherF) ? `&gg2fatherF=${this.state.gg2fatherF}` : '';
        filters += (this.state.gg1mother) ? `&gg1mother=${this.state.gg1mother}` : '';
        filters += (this.state.gg1motherF) ? `&gg1motherF=${this.state.gg1motherF}` : '';
        filters += (this.state.gg2mother) ? `&gg2mother=${this.state.gg2mother}` : '';
        filters += (this.state.gg2motherF) ? `&gg2motherF=${this.state.gg2motherF}` : '';
        filters += (this.state.ggg1father) ? `&ggg1father=${this.state.ggg1father}` : '';
        filters += (this.state.ggg1fatherF) ? `&ggg1fatherF=${this.state.ggg1fatherF}` : '';
        filters += (this.state.ggg2father) ? `&ggg2father=${this.state.ggg2father}` : '';
        filters += (this.state.ggg2fatherF) ? `&ggg2fatherF=${this.state.ggg2fatherF}` : '';
        filters += (this.state.ggg3father) ? `&ggg3father=${this.state.ggg3father}` : '';
        filters += (this.state.ggg3fatherF) ? `&ggg3fatherF=${this.state.ggg3fatherF}` : '';
        filters += (this.state.ggg4father) ? `&ggg4father=${this.state.ggg4father}` : '';
        filters += (this.state.ggg4fatherF) ? `&ggg4fatherF=${this.state.ggg4fatherF}` : '';
        filters += (this.state.ggg1mother) ? `&ggg1mother=${this.state.ggg1mother}` : '';
        filters += (this.state.ggg1motherF) ? `&ggg1motherF=${this.state.ggg1motherF}` : '';
        filters += (this.state.ggg2mother) ? `&ggg2mother=${this.state.ggg2mother}` : '';
        filters += (this.state.ggg2motherF) ? `&ggg2motherF=${this.state.ggg2motherF}` : '';
        filters += (this.state.ggg3mother) ? `&ggg3mother=${this.state.ggg3mother}` : '';
        filters += (this.state.ggg3motherF) ? `&ggg3motherF=${this.state.ggg3motherF}` : '';
        filters += (this.state.ggg4mother) ? `&ggg4mother=${this.state.ggg4mother}` : '';
        filters += (this.state.ggg4motherF) ? `&ggg4motherF=${this.state.ggg4motherF}` : '';
        

        const url = `/api/horse/list/searchAdvanced/?page=${pageNum}${filters}`
        axios.get(url)
            .then(
                res => {
                    this.setState({
                        horses: res.data.results,
                        loading: false,
                        regCount: res.data.count
                    });
                }
            )
    }

    render(){
        const { t } = this.props;
        const renderHorses = this.state.horseOptions.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.id}>{data.name} ({data.country}) {data.year}</Option>;
        });
        const renderHorsesM = this.state.horseMOptions.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.id}>{data.name} ({data.country}) {data.year}</Option>;
        });
        const renderFamilies = this.state.famOptions.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.family}>{data.family}</Option>;
        });
        return(
            <div className="layout_padding">
                {/* { localStorage.getItem('token') == null ? this.props.history.push('/login') : ''} */}
                <Spinner spinning={this.state.loading}>
                    <HorsesList data={this.state.horses} regCount={this.state.regCount} onPageSelect={this.paginate} />
                    <Form name="filterA_form" onFinish={this.onFinish}>
                        <Form.Item className='text_center'>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                {this.state.loading ? <Spinner /> : t("search")}
                            </Button>  
                        </Form.Item>
                        <table width="100%" cellPadding="5px">
                            <thead>
                                <tr>
                                    <th width='25%'>{t("1st")}</th>
                                    <th width='25%'>{t("2nd")}</th>
                                    <th width='25%'>{t("3rd")}</th>
                                    <th width='25%'>{t("4th")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowSpan="8">
                                        <HorseCardFilter className="male_card" 
                                        horses={renderHorses} onSelectHorse={(h) => this.setState({father: h})}
                                        families={renderFamilies} onSelectFamily={(f) => this.setState({ffamily: f})} />
                                    </td>
                                    <td rowSpan="4">
                                        <HorseCardFilter className="male_card" 
                                        horses={renderHorses} onSelectHorse={(h) => this.setState({ffather: h})}
                                        families={renderFamilies} onSelectFamily={(f) => this.setState({fffamily: f})} />
                                    </td>
                                    <td rowSpan="2">
                                        <HorseCardFilter className="male_card" 
                                        horses={renderHorses} onSelectHorse={(h) => this.setState({gg1father: h})}
                                        families={renderFamilies} onSelectFamily={(f) => this.setState({gg1fatherF: f})} />
                                    </td>
                                    <td>
                                        <HorseCardFilter className="male_card" 
                                        horses={renderHorses} onSelectHorse={(h) => this.setState({ggg1father: h})}
                                        families={renderFamilies} onSelectFamily={(f) => this.setState({ggg1fatherF: f})} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {/* <Card actions={[]} className="female_card void_card"></Card> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan="2"><Card actions={[]} className="female_card void_card"></Card></td>
                                    <td>
                                        <HorseCardFilter className="male_card" 
                                        horses={renderHorses} onSelectHorse={(h) => this.setState({ggg2father: h})}
                                        families={renderFamilies} onSelectFamily={(f) => this.setState({ggg2fatherF: f})} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {/* <Card actions={[]} className="female_card void_card"></Card> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan="4"><Card actions={[]} className="female_card void_card"></Card></td>
                                    <td rowSpan="2">
                                        <HorseCardFilter className="male_card" 
                                        horses={renderHorses} onSelectHorse={(h) => this.setState({gg2father: h})}
                                        families={renderFamilies} onSelectFamily={(f) => this.setState({gg2fatherF: f})} />
                                    </td>
                                    <td>
                                        <HorseCardFilter className="male_card" 
                                        horses={renderHorses} onSelectHorse={(h) => this.setState({ggg3father: h})}
                                        families={renderFamilies} onSelectFamily={(f) => this.setState({ggg3fatherF: f})} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {/* <Card actions={[]} className="female_card void_card"></Card> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan="2"><Card actions={[]} className="female_card void_card"></Card></td>
                                    <td>
                                        <HorseCardFilter className="male_card" 
                                        horses={renderHorses} onSelectHorse={(h) => this.setState({ggg4father: h})}
                                        families={renderFamilies} onSelectFamily={(f) => this.setState({ggg4fatherF: f})} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {/* <Card actions={[]} className="female_card void_card"></Card> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan="8">
                                        <HorseCardFilter className="female_card" 
                                        horses={renderHorsesM} onSelectHorse={(h) => this.setState({mother: h})}
                                        families={renderFamilies} onSelectFamily={(f) => this.setState({mfamily: f})} />
                                    </td>
                                    <td rowSpan="4">
                                        <HorseCardFilter className="male_card" 
                                        horses={renderHorses} onSelectHorse={(h) => this.setState({mfather: h})}
                                        families={renderFamilies} onSelectFamily={(f) => this.setState({mffamily: f})} />
                                    </td>
                                    <td rowSpan="2">
                                        <HorseCardFilter className="male_card" 
                                        horses={renderHorses} onSelectHorse={(h) => this.setState({gg1mother: h})}
                                        families={renderFamilies} onSelectFamily={(f) => this.setState({gg1motherF: f})} />
                                    </td>
                                    <td>
                                        <HorseCardFilter className="male_card" 
                                        horses={renderHorses} onSelectHorse={(h) => this.setState({ggg1mother: h})}
                                        families={renderFamilies} onSelectFamily={(f) => this.setState({ggg1motherF: f})} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {/* <Card actions={[]} className="female_card void_card"></Card> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan="2">
                                        <Card actions={[]} className="female_card void_card"></Card>
                                    </td>
                                    <td>
                                        <HorseCardFilter className="male_card" 
                                        horses={renderHorses} onSelectHorse={(h) => this.setState({ggg2mother: h})}
                                        families={renderFamilies} onSelectFamily={(f) => this.setState({ggg2motherF: f})} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {/* <Card actions={[]} className="female_card void_card"></Card> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan="4"><Card actions={[]} className="female_card void_card"></Card></td>
                                    <td rowSpan="2">
                                        <HorseCardFilter className="male_card" 
                                        horses={renderHorses} onSelectHorse={(h) => this.setState({gg2mother: h})}
                                        families={renderFamilies} onSelectFamily={(f) => this.setState({gg2motherF: f})} />
                                    </td>
                                    <td>
                                        <HorseCardFilter className="male_card" 
                                        horses={renderHorses} onSelectHorse={(h) => this.setState({ggg3mother: h})}
                                        families={renderFamilies} onSelectFamily={(f) => this.setState({ggg3motherF: f})} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {/* <Card actions={[]} className="female_card void_card"></Card> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan="2"><Card actions={[]} className="female_card void_card"></Card></td>
                                    <td>
                                        <HorseCardFilter className="male_card" 
                                        horses={renderHorses} onSelectHorse={(h) => this.setState({ggg4mother: h})}
                                        families={renderFamilies} onSelectFamily={(f) => this.setState({ggg4motherF: f})} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {/* <Card actions={[]} className="female_card void_card"></Card> */}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Form>                  
                </Spinner>
            </div>
        );
    }
}

export default withTranslation()(SearchAdvance);