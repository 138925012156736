import React from 'react';
import axios from 'axios';

import { Form, Input, Button } from 'antd';

class HorseForm extends React.Component {
  constructor(props) {
    super(props);
//    this.state = {value: ''};

//    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

//  handleChange(event) {
//    this.setState({value: event.target.value});
//  }

  handleSubmit(event, requestType, horseId) {
//    event.preventDefault();
    const name = event.target.elements.name.value;
    const country = event.target.elements.country.value;
    const year = event.target.elements.year.value;
    const gender = event.target.elements.gender.value;
    const family = event.target.elements.family.value;

    switch (requestType){
        case 'post':
            return axios.post('/api/horse/', {
                name:name,
                country:country,
                year:year,
                sex:gender,
                family:family
            })
            .then(res => console.log(res))
            .catch(error => console.error(error));
        case 'put':
            return axios.put(`/api/horse/${horseId}/`,
            {
                name:name,
                country:country,
                year:year,
                sex:gender,
                family:family
            })
            .then(res => console.log(res))
            .catch(error => console.error(error));
        default:
          return null;
    }
  }

  render() {
    return (
      <form name="control-hooks" onSubmit={(event) => this.handleSubmit(
                event,
                this.props.requestType,
                this.props.horseId
                )}>
            <Form.Item name="name" label="Name" rules={[{required: true, },]}>
                <Input placeholder="Horse name" />
            </Form.Item>
            <Form.Item name="country" label="Country" rules={[{required: true, },]}>
                <Input placeholder="Country" />
            </Form.Item>
            <Form.Item name="year" label="Year" rules={[{required: true, },]}>
                <Input placeholder="Year" />
            </Form.Item>
            <Form.Item name="gender" label="Gender" rules={[{required: true, },]}>
                <Input placeholder="Gender" />
            </Form.Item>
            <Form.Item name="family" label="Family" rules={[{required: true, },]}>
                <Input placeholder="Family" />
            </Form.Item>
            <Form.Item >
                <Button type="primary" htmlType="submit">
                  {this.props.btnText}
                </Button>
            </Form.Item>
      </form>
    );
  }
}

//class HorseForm extends React.Component {
//
//    handleFormSubmit = (event, requestType, horseId) => {
//        alert('hey');
//        event.preventDefault();
//        const name = event.target.elements.name.value;
//        const country = event.target.elements.country.value;
//        const year = event.target.elements.year.value;
//        const gender = event.target.elements.gender.value;
//        const family = event.target.elements.family.value;
//
//        switch (requestType){
//            case 'post':
//                return axios.post('/api/horse/', {
//                    name:name,
//                    country:country,
//                    year:year,
//                    sex:gender,
//                    family:family
//                })
//                .then(res => console.log(res))
//                .catch(error => console.err(error));
//            case 'put':
//                return axios.put(`/api/horse/${horseId}/`,
//                {
//                    name:name,
//                    country:country,
//                    year:year,
//                    sex:gender,
//                    family:family
//                })
//                .then(res => console.log(res))
//                .catch(error => console.err(error));
//        }
//
//    }
//
//    render(){
//        return (
//          <div>
//            <Form name="control-hooks" onSubmit={event => this.handleFormSubmit(
//                event,
//                this.props.requestType,
//                this.props.horseId
//                )}>
//              <Form.Item name="name" label="Name"
//                rules={[
//                  {
//                    required: true,
//                  },
//                ]}
//              >
//                <Input placeholder="Horse name" />
//              </Form.Item>
//              <Form.Item name="country" label="Country"
//                rules={[
//                  {
//                    required: true,
//                  },
//                ]}
//              >
//                <Input placeholder="Country" />
//              </Form.Item>
//              <Form.Item name="year" label="Year"
//                rules={[
//                  {
//                    required: true,
//                  },
//                ]}
//              >
//                <Input placeholder="Year" />
//              </Form.Item>
//              <Form.Item name="gender" label="Gender"
//                rules={[
//                  {
//                    required: true,
//                  },
//                ]}
//              >
//                <Select placeholder="Select" allowClear >
//                  <Option value="H">H</Option>
//                  <Option value="M">M</Option>
//                  <Option value="G">G</Option>
//                </Select>
//              </Form.Item>
//              <Form.Item name="family" label="Family"
//                rules={[
//                  {
//                    required: true,
//                  },
//                ]}
//              >
//                <Input placeholder="Horse family" />
//              </Form.Item>
//              <Form.Item >
//                <Button type="primary" htmlType="submit">
//                  {this.props.btnText}
//                </Button>
//              </Form.Item>
//            </Form>
//          </div>
//      );
//    }
//};

export default HorseForm;
