import React from 'react';
import { Modal, Button } from 'antd';
import { withTranslation } from 'react-i18next';

class Terms extends React.Component{
    state = { famOptions: [], isModalVisible: false }

    showModal = () => {
        this.setState({ isModalVisible: true});
    };

    handleCancel = () => {
        this.setState({ isModalVisible: false});
    };

    render(){
        const { t } = this.props;
        return(
            <div>
                {/* <a href="#" onClick={this.showModal}>{t("terms")}</a> */}
                <Button type="link" style={{color: '#2D0B0B', fontWeight: "bold"}} onClick={this.showModal}>{t("terms")}</Button>
                <Modal title="Terms and conditions" visible={this.state.isModalVisible} onCancel={this.handleCancel} 
                footer={null} width="80%">
                    <h2>TERMS AND CONDITIONS</h2>
Pedigreeat Consulting Ltd ("Pedigreeat") provides access to the databases ("Databases") that it administers through the pedigreeat.com website ("The Website"). The conditions under which users can access this data are described here.
<br /><br />
<h3>1. THE SERVICES</h3>

The website provides a structured interface for the information contained in the databases. Access to this information is provided to users only under the terms and conditions described in this document.
<br />
Pedigreeat will do everything possible to ensure that users receive uninterrupted access to the website. However, Pedigreeat reserves the right to modify or remove the service as it deems appropriate.
<br />
By using any of the Services on this website, whether free or paid, and by logging in or anonymously, you accept the Terms and conditions detailed in this document.
<br /><br />
<h3>2. USER CONDITIONS</h3>

Pedigreeat grants you a non-exclusive and non-transferable license to access the Service and use the Information subject to these Terms.
<br />
Copyright and other intellectual property rights protect the Service and the Information. The information may not be reproduced other than for your own personal or internal business purposes.
<br />
The information may not be used for any commercial exploitation or made available on a network or reproduced, transmitted, broadcast or displayed in public without the prior written permission of Pedigreeat.
<br />
The information may not be used to produce a thoroughbred catalog for sale at any public or private auction without Pedigreeat's prior written permission. When Pedigreeat learns of a breach of this restriction, it will have the right to take the pertinent legal actions or to have the pertinent economic damages compensated in our rates.
<br />
Our information cannot be used to produce guides of any description for commercial purposes.
<br /><br />
<h3>3. GUARANTEES AND LIABILITY</h3>

Pedigreeat warrants that it will use all reasonable skill and care to produce the Service and make it available for your access, but you acknowledge that access to it may not be uninterrupted or error-free and may be subject to delays in particular, but not only, as a result of the provision of the Service over the public Internet.
<br />
Pedigreeat makes no other representations or warranties, express or implied, with respect to the Service and the Information and all representations, warranties, terms and conditions, whether express or implied by law, common law or otherwise, are excluded in the maximum measure allowed by law. Arion does not accept any responsibility for the access or material of any service, which is linked to or from the Service.
<br />
In no event will Pedigreeat be liable for indirect, special, incidental, punitive or consequential loss or damage (including third party claims, loss of earnings, income or goodwill) suffered by you or any third party, regardless of cause, whether due to breach . contract, negligence or otherwise (including any loss or damage suffered by you as a result of an action brought by a third party) arising in connection with the Service (including errors, inaccuracies or omissions in the Information or any failure, interruption or delays in connection with the Service) Pedigreeat does not accept any responsibility for any transaction made on the basis of the Information or in connection with the Service.
<br />
Pedigreeat will not be liable for any failure, interruption or delay of the Service due to something beyond its reasonable control, such as technical failure, lightning, flood or exceptionally severe weather, fire or explosion, civil disorder, war or military operations, natural or Emergency local, any act carried out by the government or other competent authority or labor disputes of any kind. (Including those that involve your employees).
<br /><br />
<h3>4. REFUND POLICY</h3>

You acknowledge that it is your responsibility to select the Product and Horse combination that meets your requirements. Due to the nature of the information being sold, unfortunately we are unable to offer a refund except when you can clearly demonstrate that the wrong Product / Horse combination was inadvertently purchased.
<br /><br />
<h2>Privacy policies</h2>

Pedigreeat Consulting has adopted this Privacy Policy to ensure that we handle personal information in accordance with the Privacy Act.
<br />
Personal information
<br />
Pedigreeat collects, maintains and uses personal information in the following areas:
<br />
• Consumers<br />
• Business relationships<br />
<br />
Consumer information is generally collected through consumer inquiries, contests and promotions. The information may include:
<br />
• your name, address, contact number (s) and email address<br />
• information about your preference for the goods or services that we offer from time to time<br />
• details of the prizes you have won<br />
• a record of any inquiries you have made for the purposes of gathering the information<br />
<br />
Whenever possible, Pedigreeat collects this information directly from you. We use it for the following purposes:
<br />
• to contact you directly about Pedigreeat, its brands, special offers, samples and other promotions<br />
• to reward you for being a loyal Pedigreeat customer<br />
• to inform you about Pedigreeat products or publications that we think may interest you<br />
• to approve the transactions you want to make to confirm your identity<br />
• to provide you with the products you have requested<br />
• to follow up or make any inquiries you make to conduct a consumer investigation<br />
<br />
How we handle personal information
<br /><br />
Pedigreeat is dedicated to keeping personal information safe. This includes physical security, computer and network security, communications security, and personnel security.
<br /><br />

<strong>Divulge</strong>
<br /><br />
Pedigreeat does not sell, rent or make any personal information available to third parties.
<br /><br />
<strong>Access and correction of details</strong>
<br /><br />
You can access the personal information Pedigreeat holds about you by contacting us in writing. If the information we have is inaccurate, incomplete or out of date, you may request that Pedigreeat correct the information.
                </Modal>
            </div>
          );
    }
}

export default withTranslation()(Terms);