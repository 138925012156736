import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Drawer, message, List } from 'antd';
import Spinner from '../components/spinner';
import RegistrationForm from './Signup';
import LoginForm from './Login';
import { isMobile } from "react-device-detect";
import { withTranslation } from 'react-i18next';
import ArticleCard from './articles/component/articleCard';

class Index extends React.Component {
    state = {
        loadingHorses: false, loadingWin: false, loadingRaces: false, loadingOlder: false, horsesCount: 0,
        winnersCount: 0, famCount: 0, racesCount: 0, olderYear: 0, showRegister: false, itemRefs: {}, lastWeekWinners: [],
        lastWeekLoading: false, news: []
    }

    componentDidMount() {
        var now = new Date();
        this.setState({ loadingHorses: true, loadingWin: true, loadingRaces: true, loadingOlder: true, lastWeekLoading: true });
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token 03cf971bb813dfe8d93d5682cbc034c527041f77"
        }
        axios.get("/api/horse/horse/qty/")
            .then(
                res => {
                    this.setState({
                        loadingHorses: false,
                        horsesCount: res.data
                    });
                }
            )

        axios.get(`/api/winners/winners/count/`)
            .then(
                res => {
                    this.setState({
                        loadingRaces: false,
                        racesCount: res.data
                    });
                }
            )

        axios.get('/api/horse/family/qty/').then(
            res => {
                this.setState({ famCount: res.data })
            }
        )

        axios.get(`/api/winners/horses/count/`)
            .then(
                res => {
                    this.setState({
                        loadingWin: false,
                        winnersCount: res.data
                    });
                }
            )

        axios.get(`/api/horse/horse/older/`)
            .then(
                res => {
                    this.setState({
                        loadingOlder: false,
                        olderYear: res.data
                    });
                }
            )

        axios.get(`/api/winners/winners/lastWeek/`)
            .then(
                res => {
                    this.setState({
                        lastWeekLoading: false,
                        lastWeekWinners: res.data
                    });
                }
            )

        axios.get(`/api/articles/?published=${now.toISOString().split('T')[0]}&limit=3`)
            .then(
                res => {
                    this.setState({
                        news: res.data,
                        loading: false
                    });
                });
    }

    showRegister(b) {
        this.setState({ showRegister: b })
    }

    render() {
        const { t } = this.props;
        let signUpMsg = null;
        if (localStorage.getItem('signupMsg')) {
            signUpMsg = message.success(localStorage.getItem('signupMsg'));
            localStorage.removeItem('signupMsg')
        }

        return (
            <div>
                <div>
                    {signUpMsg}
                    <Row>
                        <Col id="banner" className="full_width" span={24}>
                            <a href="https://www.canva.com/design/DAE3_tJOET4/3czimuQU7tdwtqY2EytIlw/view?utm_content=DAE3_tJOET4&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink" target='_blank'>
                                <img src={process.env.PUBLIC_URL + '/img/banner.png'} className="responsive" alt="" />
                            </a>
                        </Col>
                        {/*<Col id="coverMsg">{t("coverMsg")}</Col>*/}
                    </Row>
                    {/* <Row>
                        <Col id="add1" className="text_center" span={24}>
                            <a href="https://www.trackandraces.com/#/" target='_blank'>
                                <img src={process.env.PUBLIC_URL + '/img/add1.jpg'} alt="" />
                            </a>
                        </Col>
                    </Row> */}
                    {/* <Row>
                        <Col id="add3" className="text_center" span={24} style={{ padding: "15px" }}>
                            <a href="https://www.canva.com/design/DAE9sW9AgTY/GOS9wOzr4ZuIWAeUqsal_g/edit?utm_content=DAE9sW9AgTY&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton" target='_blank'>
                                <img src={process.env.PUBLIC_URL + '/img/add5.png'} alt="" style={{ width: '400px' }} />
                            </a>
                        </Col>
                    </Row> */}
                    <div id="services" className="index_section">
                        <Row gutter={16}>
                            <Col span={24} className="title_dark">
                                {t("services")}
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                <Card title={t("search")} bordered={true} className="service_card light_bg light_border">
                                    <ul>
                                        <li>{t("serv1Desc1")}</li>
                                        <li>{t("serv1Desc2")}</li>
                                        <li>{t("serv1Desc3")}</li>
                                        <li>{t("serv1Desc4")}</li>
                                        <li>{t("serv1Desc5")}</li>
                                    </ul>
                                </Card>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                <Card title={t("advancedSearch")} bordered={true} className="service_card dark_bg dark_border">
                                    <ul>
                                        <li>{t("serv2Desc1")}</li>
                                        <li>{t("serv2Desc2")}</li>
                                        <li>{t("serv2Desc3")}</li>
                                        <li>{t("serv2Desc4")}</li>
                                        <li>{t("serv2Desc5")}</li>
                                    </ul>
                                </Card>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                <Card title={t("statistics")} bordered={true} className="service_card light_bg light_border">
                                    <ul>
                                        <li>{t("serv3Desc1")}</li>
                                        <li>{t("serv3Desc2")}</li>
                                        <li>{t("serv3Desc3")}</li>
                                        <li>{t("serv3Desc4")}</li>
                                        <li>{t("serv3Desc5")}</li>
                                    </ul>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div id="lastWeekResults" className="index_section bg_grey">
                        <Row gutter={16}>
                            <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 12 }} className="text_center imgCont">
                                <img src={process.env.PUBLIC_URL + 'img//champions.jpg'} width="70%" alt="" />
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Spinner spinning={this.state.loadingHorses}>
                                    <h3 className="title_light">{t("latestWinners")}</h3>
                                    <List grid={{ gutter: 24, column: 1 }} dataSource={this.state.lastWeekWinners} className="lastWeekList"
                                        renderItem={item => (
                                            <List.Item>
                                                <Row className="lastWeekRow">
                                                    <Col span={12}>
                                                        <a href={'/horse/ped/' + item.horse_id}><h2>{item.horse__name}</h2></a>
                                                    </Col>
                                                    <Col span={12}>
                                                        <h4>{item.comp_date} - {item.comp_name}</h4>
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        )}
                                    />
                                    {/* <Table columns={columnsWinners} dataSource={this.state.lastWeekWinners} pagination={false} /> */}
                                </Spinner>
                            </Col>
                            {/* <Col xs={{ span: 0 }} md={{ span: 2 }} lg={{ span: 3 }}></Col> */}
                        </Row>
                    </div>
                    <div id="we" className="index_section us_bg">
                        <Row>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} id="we_desc">
                                {t("usDesc")}
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} className="text_center">
                                <img src={process.env.PUBLIC_URL + 'img//logo.png'} width="50%" alt="" />
                            </Col>
                        </Row>
                    </div>
                    {/*----Indicadores Pedigreat----*/}
                    <div className="index_section">
                        <Row gutter={16}>
                            <Col span={24} className="title_dark">
                                {t("statistics")}
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}><center>
                                <Spinner spinning={this.state.loadingHorses}>
                                    <div className="statistic_card">
                                        <h3 className="title_light">{t("horses")}</h3>
                                        <h1 className="ind_light">{this.state.horsesCount}</h1>
                                    </div>
                                </Spinner></center>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}><center>
                                <Spinner spinning={this.state.loadingWin}>
                                    <div className="statistic_card">
                                        <h3 className="title_light">{t("champions")}</h3>
                                        <h1 className="ind_light">{this.state.winnersCount}</h1>
                                    </div>
                                </Spinner></center>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}><center>
                                <div className="statistic_card">
                                    <h3 className="title_light">{t("yearsOfHistory")}</h3>
                                    <h1 className="ind_light">{(new Date().getFullYear()) - 2000}+</h1>
                                </div>
                            </center>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}><center>
                                <Spinner spinning={this.state.loadingHorses}>
                                    <div className="statistic_card">
                                        <h3 className="title_light">{t("families")}</h3>
                                        <h1 className="ind_light">{this.state.famCount}</h1>
                                    </div>
                                </Spinner></center>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}><center>
                                <Spinner spinning={this.state.loadingRaces}>
                                    <div className="statistic_card">
                                        <h3 className="title_light">{t("registeredRaces")}</h3>
                                        <h1 className="ind_light">{this.state.racesCount}</h1>
                                    </div>
                                </Spinner></center>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}><center>
                                <Spinner spinning={this.state.loadingOlder}>
                                    <div className="statistic_card">
                                        <h3 className="title_light">{t("horsesSince")}</h3>
                                        <h1 className="ind_light">{this.state.olderYear}</h1>
                                    </div>
                                </Spinner>
                            </center>
                            </Col>
                        </Row>
                    </div>
                    {/*---- Fin Indicadores Pedigreat----*/}
                    {/*----Articulos Pedigreat----*/}
                    {/*<div id="articles" className="index_section bg_grey">*/}
                    {/*    <Row gutter={16}>*/}
                    {/*        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>*/}
                    {/*            <Spinner spinning={this.state.loadingHorses}>*/}
                    {/*                <h3 className="title_light">{t("latestArticles")}</h3>*/}
                    {/*                <Link to="/articles" className='allLink'><h4 className="title_light">{t('seeAll')}</h4></Link>*/}
                    {/*                <List grid={{ gutter: 24, column: 3 }} dataSource={this.state.news}*/}
                    {/*                    renderItem={item => (*/}
                    {/*                        <List.Item>*/}
                    {/*                            <center><ArticleCard data={item} /></center>*/}
                    {/*                        </List.Item>*/}
                    {/*                    )}*/}
                    {/*                />*/}
                    {/*            </Spinner>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</div>*/}
                    {/*---- Fin Articulos Pedigreat----*/}
                    <Drawer
                        title="Sign up"
                        width={isMobile ? "80%" : "40%"}
                        placement="right"
                        closable={true}
                        onClose={() => this.showRegister(false)}
                        visible={this.state.showRegister}
                    >
                        <RegistrationForm />
                        <h2 className="text_center">Got an account already?</h2>
                        <LoginForm />
                    </Drawer>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Index);