import React from 'react';
import { Card, Tooltip, Badge, Popover } from 'antd';
import '../css/card.css';
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const { Meta } = Card;

const Horsecard = (props) =>{
  const renderWins = props.data.wins.map(function (data, idx) {
    return <div>{data.comp_date} - {data.comp_name} - Grade {data.comp_grade}</div>;
  });

  const { t } = useTranslation();

  return(
    <Popover content={renderWins} title={t("championOn")} trigger="hover">
      <Badge count={props.data.wins_count} className="full_width" style={{ backgroundColor: '#faa307', fontWeight: 'bold', fontSize: '12px' }}>
        <Card className={(props.data.sex === 'M' || props.data.sex === 'F' ? 'female_card' : 'male_card')} 
          actions={ isMobile ? '' : [
            <Tooltip placement="bottom" title={t("country")}>
              <div>{props.data.country}</div>
            </Tooltip>,
            <Tooltip placement="bottom" title={t("family")}>
              <div>{props.data.family}</div>
            </Tooltip>,
            <Tooltip placement="bottom" title={t("year")}>
              <div>{props.data.year}</div>
            </Tooltip>,
          ]}
        >
          <Meta
            title= {<center><a href={'/horse/ped/'+props.data.id}>{props.data.name}</a></center>}
          />
        </Card>
      </Badge>
    </Popover>
  );
}

export default Horsecard;