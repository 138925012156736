import React from 'react';
import axios from 'axios';
import { Row, Col, Divider } from 'antd';
import HorseCard from '../components/HorseCard';
import Spinner from '../components/spinner';
import TreeLine from '../components/TreeLines';
import '../css/tree.css';

class HorsePedigreeVert extends React.Component{
    state = {
        horse: [],
        horseId: this.props.match.params.horseId,
        loading: true
    }

    componentDidMount(){
        const horseId = this.props.match.params.horseId;
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token 03cf971bb813dfe8d93d5682cbc034c527041f77"
        }
        axios.get(`http://127.0.0.1:8000/horse/horsePed/${horseId}/`)
            .then(
                res => {
                    this.setState({
                        horse: res.data,
                        loading: false
                    });
                }
            )
    }

    render(){
        var horses = this.state.horse;
        var horse = horses[0];
        if(horses[1]){
            var father = horses[1].find((h)=> h.id === horse.father_id ? h : null);
            var mother = horses[1].find((h)=> h.id === horse.mother_id ? h : null);
        }
        if(horses[2]){
            var ffather = horses[2].find((h)=> h.id === father.father_id ? h : null);
            var fmother = horses[2].find((h)=> h.id === father.mother_id ? h : null);
            var mfather = horses[2].find((h)=> h.id === mother.father_id ? h : null);
            var mmother = horses[2].find((h)=> h.id === mother.mother_id ? h : null);
        }
        if(horses[3]){
            var gf1father = ffather ? horses[3].find((h)=> h.id === ffather.father_id ? h : null) : null;
            var gf1mother = ffather ? horses[3].find((h)=> h.id === ffather.mother_id ? h : null) : null;
            var gm1father = fmother ? horses[3].find((h)=> h.id === fmother.father_id ? h : null) : null;
            var gm1mother = fmother ? horses[3].find((h)=> h.id === fmother.mother_id ? h : null) : null;
            var gf2father = mfather ? horses[3].find((h)=> h.id === mfather.father_id ? h : null) : null;
            var gf2mother = mfather ? horses[3].find((h)=> h.id === mfather.mother_id ? h : null) : null;
            var gm2father = mmother ? horses[3].find((h)=> h.id === mmother.father_id ? h : null) : null;
            var gm2mother = mmother ? horses[3].find((h)=> h.id === mmother.mother_id ? h : null) : null;
        }
        if(horses[4]){
            var gf3father = gf1father ? horses[4].find((h)=> h.id === gf1father.father_id ? h : null) : null;
            var gf3mother = gf1father ? horses[4].find((h)=> h.id === gf1father.mother_id ? h : null) : null;
            var gm3father = gf1mother ? horses[4].find((h)=> h.id === gf1mother.father_id ? h : null) : null;
            var gm3mother = gf1mother ? horses[4].find((h)=> h.id === gf1mother.mother_id ? h : null) : null;
            var gf4father = gm1father ? horses[4].find((h)=> h.id === gm1father.father_id ? h : null) : null;
            var gf4mother = gm1father ? horses[4].find((h)=> h.id === gm1father.mother_id ? h : null) : null;
            var gm4father = gm1mother ? horses[4].find((h)=> h.id === gm1mother.father_id ? h : null) : null;
            var gm4mother = gm1mother ? horses[4].find((h)=> h.id === gm1mother.mother_id ? h : null) : null;
            var gf5father = gf2father ? horses[4].find((h)=> h.id === gf2father.father_id ? h : null) : null;
            var gf5mother = gf2father ? horses[4].find((h)=> h.id === gf2father.mother_id ? h : null) : null;
            var gm5father = gf2mother ? horses[4].find((h)=> h.id === gf2mother.father_id ? h : null) : null;
            var gm5mother = gf2mother ? horses[4].find((h)=> h.id === gf2mother.mother_id ? h : null) : null;
            var gf6father = gm2father ? horses[4].find((h)=> h.id === gm2father.father_id ? h : null) : null;
            var gf6mother = gm2father ? horses[4].find((h)=> h.id === gm2father.mother_id ? h : null) : null;
            var gm6father = gm2mother ? horses[4].find((h)=> h.id === gm2mother.father_id ? h : null) : null;
            var gm6mother = gm2mother ? horses[4].find((h)=> h.id === gm2mother.mother_id ? h : null) : null;
        }
        
        return(
            <div className="layout_padding">
                <Spinner spinning={this.state.loading}>
                    <Row>
                        <Col xs={{ span: 6 }} md={{ span: 8 }} lg={{ span: 10 }}></Col>
                        <Col xs={{ span: 12 }} md={{ span: 8 }} lg={{ span: 4 }}>
                            {horse ? <HorseCard data={horse} /> : '' }
                        </Col>
                        <Col xs={{ span: 6 }} md={{ span: 8 }} lg={{ span: 10 }}></Col>
                    </Row>
                    <TreeLine />
                    <Col xs={{ span: 24 }} md={{ span: 0 }} lg={{ span: 0}}>
                        <Divider orientation="left">1era</Divider>
                    </Col>
                    <Row>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 4 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 8 }} lg={{ span: 4 }}>
                            {father ? <HorseCard data={father} /> : ''}
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 4 }} lg={{ span: 8 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 8 }} lg={{ span: 4 }}>
                            {mother ? <HorseCard data={mother} /> : ''}
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 4 }}></Col>
                    </Row>
                    <Row>
                        <Col lg={{ span: 12 }}>
                            <TreeLine />
                        </Col>
                        <Col lg={{ span: 12 }}>
                            <TreeLine />
                        </Col>
                    </Row>
                    <Col xs={{ span: 24 }} md={{ span: 0 }} lg={{ span: 0}}>
                        <Divider orientation="left">2da</Divider>
                    </Col>
                    <Row>
                        <Col xs={{ span: 2 }} md={{ span: 1 }} lg={{ span: 2 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 3 }} className='tree_margin_1'>
                            {ffather ? <HorseCard data={ffather} /> : '' }
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 3 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 3 }}>
                            {fmother ? <HorseCard data={fmother} /> : '' }
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }}></Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 3 }} className='tree_margin_2'>
                            {mfather ? <HorseCard data={mfather} /> : '' }
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 3 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 3 }}>
                            {mmother ? <HorseCard data={mmother} /> : '' }
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 1 }} lg={{ span: 2 }}></Col>
                    </Row>
                    <Row>
                        <Col lg={{ span: 6 }}>
                            <TreeLine />
                        </Col>
                        <Col lg={{ span: 6 }}>
                            <TreeLine />
                        </Col>
                        <Col lg={{ span: 6 }}>
                            <TreeLine />
                        </Col>
                        <Col lg={{ span: 6 }}>
                            <TreeLine />
                        </Col>
                    </Row>
                    <Col xs={{ span: 24 }} md={{ span: 0 }} lg={{ span: 0}}>
                        <Divider orientation="left">3era</Divider>
                    </Col>
                    <Row>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 1 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_1'>
                            {gf1father  ? <HorseCard data={gf1father} /> : '' }
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_2'>
                            {gf1mother ? <HorseCard data={gf1mother} /> : ''}
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_3'>
                            {gm1father ? <HorseCard data={gm1father} /> : '' }
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_2'>
                            {gm1mother ? <HorseCard data={gm1mother} /> : ''}
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_3'>
                            {gf2father ? <HorseCard data={gf2father} /> : ''}
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_2'>
                            {gf2mother ? <HorseCard data={gf2mother} /> : ''}
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_3'>
                            {gm2father ? <HorseCard data={gm2father} /> : '' }
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_2'>
                            {gm2mother ? <HorseCard data={gm2mother} /> : '' }
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 1 }}></Col>
                    </Row>
                    <Row>
                        <Col lg={{ span: 1 }}></Col>
                        <Col lg={{ span: 2 }} className='tree_margin_1'>
                            <TreeLine single={1} />
                        </Col>
                        <Col lg={{ span: 2 }} className='tree_margin_2'>
                            <TreeLine single={1} />
                        </Col>
                        <Col lg={{ span: 2 }}></Col>
                        <Col lg={{ span: 2 }} className='tree_margin_3'>
                            <TreeLine single={1} />
                        </Col>
                        <Col lg={{ span: 2 }} className='tree_margin_2'>
                            <TreeLine single={1} />
                        </Col>
                        <Col lg={{ span: 2 }}></Col>
                        <Col lg={{ span: 2 }} className='tree_margin_3'>
                            <TreeLine single={1} />
                        </Col>
                        <Col lg={{ span: 2 }} className='tree_margin_2'>
                            <TreeLine single={1} />
                        </Col>
                        <Col lg={{ span: 2 }}></Col>
                        <Col lg={{ span: 2 }} className='tree_margin_3'>
                            <TreeLine single={1} />
                        </Col>
                        <Col lg={{ span: 2 }} className='tree_margin_2'>
                            <TreeLine single={1} />
                        </Col>
                        <Col lg={{ span: 1 }}></Col>
                    </Row>
                    <Col xs={{ span: 24 }} md={{ span: 0 }} lg={{ span: 0}}>
                        <Divider orientation="left">4ta</Divider>
                    </Col>
                    <Row>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 1 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_1'>
                            {gf3father  ? <HorseCard data={gf3father} /> : '' }
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_2'>
                            {gm3father ? <HorseCard data={gm3father} /> : ''}
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_3'>
                            {gf4father ? <HorseCard data={gf4father} /> : '' }
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_2'>
                            {gm4father ? <HorseCard data={gm4father} /> : ''}
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_3'>
                            {gf5father ? <HorseCard data={gf5father} /> : ''}
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_2'>
                            {gm5father ? <HorseCard data={gm5father} /> : ''}
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_3'>
                            {gf6father ? <HorseCard data={gf6father} /> : '' }
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_2'>
                            {gm6father ? <HorseCard data={gm6father} /> : '' }
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 1 }}></Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 1 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_1'>
                            {gf3mother  ? <HorseCard data={gf3mother} /> : '' }
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_2'>
                            {gm3mother ? <HorseCard data={gm3mother} /> : ''}
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_3'>
                            {gf4mother ? <HorseCard data={gf4mother} /> : '' }
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_2'>
                            {gm4mother ? <HorseCard data={gm4mother} /> : ''}
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_3'>
                            {gf5mother ? <HorseCard data={gf5mother} /> : ''}
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_2'>
                            {gm5mother ? <HorseCard data={gm5mother} /> : ''}
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_3'>
                            {gf6mother ? <HorseCard data={gf6mother} /> : '' }
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 0 }} lg={{ span: 0 }}></Col>
                        <Col xs={{ span: 9 }} md={{ span: 4 }} lg={{ span: 2 }} className='tree_margin_2'>
                            {gm6mother ? <HorseCard data={gm6mother} /> : '' }
                        </Col>
                        <Col xs={{ span: 2 }} md={{ span: 2 }} lg={{ span: 1 }}></Col>
                    </Row>
                </Spinner>
            </div>
        );
    }
}

export default HorsePedigreeVert;