import React from 'react';
import axios from 'axios';
import { Select } from 'antd';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

class FamilyFilter extends React.Component{
    state = { famOptions: [], }

    componentDidMount(){           
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token 03cf971bb813dfe8d93d5682cbc034c527041f77"
        }
        axios.get('/api/horse/family/list/').then(
            res => {
                this.setState({famOptions: res.data})
            }
        )
    }

    handleChange = (optionSelected) => {
        this.props.onSelectFamily(optionSelected);            
    }

    render(){
        const { t } = this.props;
        const renderFamilies = this.state.famOptions.map(function (data, idx) {
            return <Option key={idx} id={idx} value={data.family}>{data.family}</Option>;
        });
        return(
            <Select 
                showSearch
                placeholder={t("family")}
                optionFilterProp="children"
                onChange={this.handleChange}
                allowClear
            >
                {renderFamilies}
            </Select>
          );
    }
}

export default withTranslation()(FamilyFilter);