import React from 'react';
import axios from 'axios';
import { Row, Col, Form, Button } from 'antd';
import Spinner from '../components/spinner';
import StudsList from '../components/StudsList';
import FamilyFilter from '../components/FamilyFilter';
import { withTranslation } from 'react-i18next';

class Stud extends React.Component{
    state = {
        horses: [], loading: false, regCount:0, famOptions: [],
        fname: '',
        ffamily: '',
        fcompetition: '',
    }

    componentDidMount() {           
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token 03cf971bb813dfe8d93d5682cbc034c527041f77"
        }
    }

    onFinish = values => {
        this.setState({fname: values.filter_name});
        this.paginate(1);
    };

    paginate = pageNum => {
        this.setState({loading: true});
        const url = `/api/horse/studs/list/?page=${pageNum}&filter_family=${this.state.ffamily}`
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: "Token 03cf971bb813dfe8d93d5682cbc034c527041f77"
        }
        axios.get(url)
            .then(
                res => {
                    this.setState({
                        horses: res.data.results,
                        loading: false,
                        regCount: res.data.count
                    });
                }
            )
    }

    handleFamilyFilter = (family) => {
        this.setState({ffamily: family});
    }

    render(){
        const { t } = this.props;

        return(
            <div className="layout_padding">
                <Form name="filter_form" onFinish={this.onFinish}>
                    <Row>
                        <Col xs={{ span: 20 }} md={{ span: 20 }} lg={{ span: 20 }} className="filter_field">
                            <Form.Item name="filter_family">
                                <FamilyFilter onSelectFamily={this.handleFamilyFilter} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }} className="text_center">
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    {this.state.loading ? <Spinner /> : t("search")}
                                </Button>  
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <Spinner spinning={this.state.loading}>
                    <StudsList data={this.state.horses} regCount={this.state.regCount}
                        onPageSelect={this.paginate} defPage={1} />
                </Spinner>
            </div>
        );
    }
}

export default withTranslation()(Stud);