import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Spinner = (props) => {
    return(<Spin indicator={spinIcon} {...props} />)
}

export default Spinner;