import React from 'react';
import i18n from '../i18n';
import { withTranslation } from 'react-i18next';
import { Select } from 'antd';
import '../css/langSelect.css';

const { Option } = Select;

class LangSelect extends React.Component{
    handleChange = (event) => {
        i18n.changeLanguage(event);           
    }

    render(){
        const selected = localStorage.getItem("i18nextLng") || "en";
         return(
            <Select defaultValue={selected} onChange={this.handleChange} className="langSelect">
                <Option value="en" className="langSelectOpt"><img src={process.env.PUBLIC_URL + '/img/en.png'} className="iconFlag" alt="" /></Option>
                <Option value="es" className="langSelectOpt"><img src={process.env.PUBLIC_URL + '/img/es.png'} className="iconFlag" alt="" /></Option>
            </Select>
          );
    }
}

export default withTranslation()(LangSelect);