import React from 'react';
import {connect} from 'react-redux';
import { Form, Input, Button, Alert, Row, Col } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import * as actions from '../store/actions/auth';
import Spinner from '../components/spinner';
import { useTranslation } from "react-i18next";

const ForgotPassForm = (props) => {
  const { t } = useTranslation();
  const onFinish = values => {
    props.sendResetEmail(values.email);
  };

  let errorMessage = null;
    if(props.error){
        if(props.error.error.error){
            errorMessage = (
                <Alert message={t(props.error.error.error)} type="error" showIcon closable className="with_space" />
            )
        }else{
            if(props.error.error.success){
                errorMessage = (
                    <Alert message={t(props.error.error.success)} type="success" showIcon closable className="with_space" />
                )
            }
        }
    }

  return (
      <div>
        { errorMessage}
        { props.token ? window.location.replace('/')
        :
            <Spinner spinning={props.loading}>
              <Row className="layout_padding">
                <Col xs={{ span: 0 }} md={{ span: 8 }} lg={{ span: 8 }}></Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <h2 className="title">{t("passwordReset")}</h2>
                  <Form
                      name="normal_forgot" className="forgot-form"
                      initialValues={{
                          remember: true,
                      }}
                      onFinish={onFinish}
                      >
                      <Form.Item name="email"
                          rules={[
                          {
                              required: true,
                              message: t("emailValMsg"),
                          },
                          ]}
                      >
                          <Input prefix={<MailOutlined className="site-form-item-icon" />} type="email" placeholder={t("email")} />
                      </Form.Item>
                      <Form.Item className="text_center">
                          <Button type="primary" htmlType="submit" className="login-form-button">
                          {t("send")}
                          </Button>  
                      </Form.Item>
                  </Form>
                </Col>
                <Col xs={{ span: 0 }} md={{ span: 8 }} lg={{ span: 8 }}></Col>
              </Row>
            </Spinner>
        }
        </div>
  );
};

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendResetEmail: (email) => dispatch(actions.forgotPassword(email))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassForm);