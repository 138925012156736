import React from 'react';
import { List, Row, Col, Pagination, Radio, Table, Popover } from 'antd';
import HorseCard from './HorseCard';
import { withTranslation } from 'react-i18next';

class HorsesList extends React.Component{
    state = {view: 'Standar', currentPage: this.props.defPage }

    paginate = (pageNum) => {
        this.setState({currentPage: pageNum});
        this.props.onPageSelect(pageNum);            
    }

    changeView = (v) =>{
        this.setState({view:v.target.value});
    }

    render(){
        const { t } = this.props;
        const columns = [
            {
                title: t("victories"),
                dataIndex: 'wins_count',
                render: (text, record) => { 
                        let renderWins = record.wins.map(function (data, idx) {
                          return <div>{data.comp_date} - {data.comp_name} - Grade {data.comp_grade}</div>});
                    return <Popover content={renderWins} title="Champion on" trigger="hover">{text}</Popover>},
            },
            {
              title: t("name"),
              dataIndex: 'name',
              render: (text, record) => ( <a href={'/horse/ped/'+record.id}>{text}</a> ),
            },
            {
                title: t("family"),
                dataIndex: 'family',
            },
            {
                title: t("fatherFamily"),
                dataIndex: 'father_family',
            },
            {
                title: t("country"),
                dataIndex: 'country',
              },
              {
                title: t("year"),
                dataIndex: 'year',
              },
          ];
        return(
            <div>
                {(this.state.view === 'Standar') ? 
                    <List grid={{ gutter: 16, xs: 1, md: 3, lg: 4, column: 4 }}
                        dataSource={this.props.data}
                        renderItem={item => (
                        <List.Item>
                            <HorseCard data={item} />
                        </List.Item>
                        )}
                    />
                : <Table columns={columns} dataSource={this.props.data} pagination={false} /> }
                <Row className="bottomBar">
                    <Col xs={{ span: 4 }} md={{ span: 6 }} lg={{ span: 8 }}>
                    <Radio.Group
                        options={[ { label: t("view"), value: 'View', disabled: true },
                            { label: t("standar"), value: 'Standar' }, { label: t("list"), value: 'List' },]}
                        onChange={this.changeView}
                        value={this.state.view}
                        optionType="button"
                        buttonStyle="solid"
                    />
                    </Col>
                    <Col xs={{ span: 16 }} md={{ span: 12 }} lg={{ span: 8 }} className="text_center">
                        <Pagination size="small" current={this.state.currentPage} total={this.props.regCount}
                        onChange={this.paginate} showSizeChanger={false} defaultPageSize={20} />
                    </Col>
                    <Col xs={{ span: 4 }} md={{ span: 6 }} lg={{ span: 8 }} className="text_right">
                        {t("registers")}: {this.props.regCount}</Col>
                </Row>
            </div>
          );
    }
}

export default withTranslation()(HorsesList);