import React from 'react';
import { Route } from 'react-router-dom';
import Index from './containers/Index';
import Search from './containers/Search';
import HorsesList from './containers/HorsesListView';
// import HorsesDetail from './containers/HorsesDetailView';
// import Login from './containers/Login';
import Signup from './containers/Signup';
import SearchAdvance from './containers/SearchAdvance';
// import SearchAdvanceVert from './containers/SearchAdvance_vertical';
import LogSign from './containers/logSing';
import HorsePedigree from './containers/HorsePedigree';
import HorsePedigreeVert from './containers/HorsePedigree_vertical';
import Statistics from './containers/statistics';
import ForgotPassForm from './containers/ForgotPass';
import ChangePass from './containers/CahngePass';
import ResetPass from './containers/ResetPass';
import scoring from './containers/scoring/index';
import match from './containers/match/index';
import Studs from './containers/Studs';
import Names from './containers/Names';
// import Article from './containers/articles/Article';
// import Articles from './containers/articles/Articles';


const BaseRouter = () => (
    <div>
        <Route exact path='/' component={Index} />
        <Route exact path='/search/' component={Search} />
        <Route exact path='/horse/list' component={HorsesList} />
        <Route exact path='/horse/ped/:horseId' component={HorsePedigree} />
        <Route exact path='/horse/pedVert/:horseId' component={HorsePedigreeVert} />
        {/* <Route exact path='/admin/horse/:horseId' component={HorsesDetail} /> */}
        <Route exact path='/login/' component={LogSign} />
        <Route exact path='/searchAdvanced/' component={SearchAdvance} />
        <Route exact path='/statistics/' component={Statistics} />
        {/* <Route exact path='/searchAdvancedVert/' component={SearchAdvanceVert} /> */}
        <Route exact path='/signup/' component={Signup} />
        <Route exact path='/forgotPass/' component={ForgotPassForm} />
        <Route exact path='/reset/password/:uid/:token' component={ResetPass} />
        <Route exact path='/changePass/' component={ChangePass} />
        <Route exact path='/scoring/' component={scoring} />
        <Route exact path='/match/' component={match} />
        <Route exact path='/studs/' component={Studs} />
        <Route exact path='/names/' component={Names} />
        {/* <Route exact path='/article/:id' component={Article} />
        <Route exact path='/articles/' component={Articles} /> */}
    </div>
);

export default BaseRouter;