import React from 'react';
import { Card } from 'antd';
import '../../../css/articles.css';
import { useTranslation } from "react-i18next";

const { Meta } = Card;

const ArticleCard = (props) =>{

  const { t } = useTranslation();

  return(
    <Card className='articleCard'
        style={{ width: 300 }}
        cover={
            <img src={process.env.PUBLIC_URL + '/img/banner.jpg'} className="responsive" alt="" />
        }
    >
        <Meta
        title={<center><a href={'/article/'+props.data.uuid}>{props.data['title_'+localStorage.getItem("i18nextLng")]}</a></center>}
        description={<p dangerouslySetInnerHTML={{__html:props.data['body_'+localStorage.getItem("i18nextLng")].substring(0, 100)}}/>}
        />
    </Card>
  );
}

export default ArticleCard;