import React from 'react';
import {connect} from 'react-redux';
import { Form, Input, Button, Alert } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import * as actions from '../store/actions/auth';
import Spinner from '../components/spinner';
import { useTranslation } from "react-i18next";

const LoginForm = (props) => {
  const onFinish = values => {
    props.onAuth(values.email, values.password);
  };

  let errorMessage = null;
    if(props.error){
        errorMessage = (
            <Alert message="Wrong credentials, try again" type="error" showIcon closable className="with_space" />
        )
    }

    const { t } = useTranslation();

  return (
      <div>
        { errorMessage}
        { props.token ? window.location.replace('/')
        :
            <Spinner spinning={props.loading}>
                <Form
                    name="normal_login" className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    >
                    <Form.Item name="email"
                        rules={[
                        {
                            required: true,
                            message: t("emailValMsg"),
                        },
                        ]}
                    >
                        <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder={t("email")} />
                    </Form.Item>
                    <Form.Item name="password"
                        rules={[
                        {
                            required: true,
                            message: t("passwordValMsg"),
                        },
                        ]}
                    >
                        <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />} placeholder={t("password")}
                        />
                    </Form.Item>
                    <Form.Item>
                        {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Remember me</Checkbox>
                        </Form.Item> */}

                        <a className="login-form-forgot" href="/forgotPass/">
                            {t("forgotPassword")}
                        </a>
                    </Form.Item>

                    <Form.Item className="text_center">
                        <Button type="primary" htmlType="submit" className="login-form-button">
                        {t("logIn")}
                        </Button>  
                        {/* <p>Or </p>
                        <a href="/signup">register now!</a> */}
                    </Form.Item>
                </Form>
            </Spinner>
        }
        </div>
  );
};

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error,
        token: state.token,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.authLogin(email, password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);