import React from 'react';
import axios from 'axios';

import HorseCard from '../components/HorseCard'
import HorseForm from '../components/HorseForm'

class HorsesList extends React.Component{
    state = {
        horses: []
    }

    componentDidMount(){
        axios.get('/api/horse/')
            .then(
                res => {
                    this.setState({
                        horses: res.data
                    });
                }
            )
    }

    render(){
        return(
            <div>
                <h2>New Horse</h2>
                <HorseForm requestType="post" horseId={null} btnText="Save" />
                <HorseCard data={this.state.horses} />
            </div>
        );
    }
}

export default HorsesList;