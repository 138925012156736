import React from 'react';
import {connect} from 'react-redux';
import { Form, Input, Button, Alert, Modal, Radio } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import * as actions from '../store/actions/auth';
import Spinner from '../components/spinner';
import Terms from '../components/terms';
import { useTranslation } from "react-i18next";

const RegistrationForm = (props) => {
  let resMsg = null;
  // let acepptTerms = false;
  const [form] = Form.useForm();
  
  const register = values => {
    if(values.aterms === 1){
      props.onRegister(values.firstName, values.lastName, values.email, values.password, values.confirm)
      // if(props.onRegister(values.firstName, values.lastName, values.email, values.password, values.confirm)){
      //   message.success('Welcome to horseeker.com');
      //   props.history.push('/login');
      // }
    }else{
      Modal.error({content: "Please accept terms and conditions to continue"});
    }
  };

  if(props.errorReg){
    try{
      resMsg = props.errorReg.response.data.msgs.map(function(val, key){
        return <Alert message={val} type="error" showIcon closable className="with_space" />
      })
    }catch (e){}
  }

  // const acceptTerm = obj => {
  //   acepptTerms = obj.target.checked;
  // }

  const { t } = useTranslation();

  return (
    <Spinner spinning={props.loading}>
      {resMsg}
      <Form form={form} name="register" onFinish={register} scrollToFirstError >
        <Form.Item name="firstName"
            rules={[
            {
                required: true,
                message: t("firstNameValMsg"),
            },
            ]}
        >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t("firstName")} />
        </Form.Item>
        <Form.Item name="lastName"
            rules={[
            {
                required: true,
                message: t("lastNameValMsg"),
            },
            ]}
        >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t("lastName")} />
        </Form.Item>
        <Form.Item name="email"
          rules={[
            {
              type: 'email',
              message: t("emailInvMsg"),
            },
            {
              required: true,
              message: t("emailValMsg"),
            },
          ]}
        >
          <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder={t("email")} />
        </Form.Item>
        <Form.Item name="password"
          rules={[
            {
              required: true,
              message: t("passwordValMsg"),
            },
          ]}
          hasFeedback
        >
          <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder={t("password")} />
        </Form.Item>

        <Form.Item name="confirm" dependencies={['password']} hasFeedback
          rules={[
            {
              required: true,
              message: t("confirmPasswordValMsg"),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(t("passNoMatch"));
              },
            }),
          ]}
        >
          <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder={t("confirmPassword")} />
        </Form.Item>
        <Terms />
          <br />
          {t("acceptTerms")} <br />
        <Form.Item name="aterms">
          <Radio.Group buttonStyle="solid" >
              <Radio.Button value={1}>{t("yes")}</Radio.Button>
              <Radio.Button value={0}>{t("no")}</Radio.Button>
          </Radio.Group>
          {/* <Checkbox onChange={acceptTerm} ref={useRef('chkTerms')}>I accept terms and conditions</Checkbox> */}
        </Form.Item>
        <Form.Item className="text_center">
          <Button type="primary" htmlType="submit">{t("signUp")} </Button>
        </Form.Item>
      </Form>
    </Spinner>
  );
};

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        errorReg: state.error,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRegister: (firstName, lastName, email, password, confirm) => dispatch(actions.authSignup(firstName, lastName, email, password, confirm))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
