import React, { Component } from 'react';
import 'antd/dist/antd.css';
import CustomLayout from './containers/Layout';
import { connect } from 'react-redux';
import * as actions from './store/actions/auth';
// import ReactGA from 'react-ga';
// import { createBrowserHistory } from 'history';

import { BrowserRouter as Router } from 'react-router-dom';
import BaseRouter from './routes';

// const history = createBrowserHistory();

  // Initialize google analytics page view tracking
  // history.listen(location => {
  //   ReactGA.set({ page: location.pathname }); // Update the user's current page
  //   ReactGA.pageview(location.pathname); // Record a pageview for the given page
  // });
  
class App extends Component { 
  componentDidMount() {
    this.props.onTryAutoSignup()
  }

  render() {
    return (
      <div>
          <Router>
            <CustomLayout { ...this.props }>
              <BaseRouter />
            </CustomLayout>
          </Router>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isauthenticated: (state.token != null) ? state.token : 'false',
    token: state.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.autoCheckState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
