import React from 'react';
import { Row, Col } from 'antd';

const TreeLines = (props) =>{
  return(
    <div>
      {(props.single === 1) ? 
        <Row>
            <Col xs={{ span: 0 }} md={{ span: 6 }} lg={{ span: 6 }}>&nbsp;</Col>
            <Col xs={{ span: 0 }} md={{ span: 6 }} lg={{ span: 6 }} className='line_right' >&nbsp;</Col>
            <Col xs={{ span: 0 }} md={{ span: 6 }} lg={{ span: 6 }} className='line_left'>&nbsp;</Col>
            <Col xs={{ span: 0 }} md={{ span: 6 }} lg={{ span: 6 }}>&nbsp;</Col>
        </Row>
      : 
        <div>
          <Row>
              <Col xs={{ span: 0 }} md={{ span: 6 }} lg={{ span: 6 }}>&nbsp;</Col>
              <Col xs={{ span: 0 }} md={{ span: 6 }} lg={{ span: 6 }} className='line_bottom line_right' >&nbsp;</Col>
              <Col xs={{ span: 0 }} md={{ span: 6 }} lg={{ span: 6 }} className='line_bottom line_left'>&nbsp;</Col>
              <Col xs={{ span: 0 }} md={{ span: 6 }} lg={{ span: 6 }}>&nbsp;</Col>
          </Row>
          <Row>
            <Col xs={{ span: 0 }} md={{ span: 6 }} lg={{ span: 6 }}>&nbsp;</Col>
            <Col xs={{ span: 0 }} md={{ span: 6 }} lg={{ span: 6 }} className='line_left' >&nbsp;</Col>
            <Col xs={{ span: 0 }} md={{ span: 6 }} lg={{ span: 6 }} className='line_right'>&nbsp;</Col>
            <Col xs={{ span: 0 }} md={{ span: 6 }} lg={{ span: 6 }}>&nbsp;</Col>
          </Row>
        </div>
      }
    </div>
  );
}

export default TreeLines;