import React from 'react';
import { Row, Col} from 'antd';
import LoginForm from './Login';
import RegistrationForm from './Signup';
import { withTranslation } from 'react-i18next';

class LogSign extends React.Component{
    render(){
        const { t } = this.props;
        return(
            <Row className="layout_padding">
                <Col xs={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }}></Col>
                <Col xs={{ span: 24 }} md={{ span: 10 }} lg={{ span: 8 }}>
                    <h2 className="title">{t("logIn")}</h2>
                    <LoginForm />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 2 }} lg={{ span: 2 }}>
                    <h2 className="title">{t("or")}</h2>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 10 }} lg={{ span: 8 }}>
                    <h2 className="title">{t("signUp")}</h2>
                    <RegistrationForm />
                </Col>
                <Col xs={{ span: 0 }} md={{ span: 1 }} lg={{ span: 3 }}></Col>
            </Row>
        )
    }
}

export default withTranslation()(LogSign);