import React, {useState} from 'react';
import {connect} from 'react-redux';
import { Form, Input, Button, Alert, Row, Col } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import * as actions from '../store/actions/auth';
import Spinner from '../components/spinner';
import { useTranslation } from "react-i18next";
import validator from 'validator'

const ChangePass = (props) => {
    const { t } = useTranslation();
    const [strongPass, setStrongPass] = useState('')
    let errorMessage = null;
  const onFinish = values => {
    setStrongPass('');
    if (validator.isStrongPassword(values.password, {
    minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1
    })) {
        props.changePass(values.old_pass, values.password, values.confirm);
    } else {
        setStrongPass(<Alert message={t("passStrongMsg")} type="error" showIcon className="with_space" />);
    }
  };

    if(props.error){
        if(props.error.error.error){
            errorMessage = (
                <Alert message={t(props.error.error.error)} type="error" showIcon closable className="with_space" />
            )
        }else{
            if(props.error.error.success){
                errorMessage = (
                    <Alert message={t(props.error.error.success)} type="success" showIcon closable className="with_space" />
                )
            }
        }
    }

  return (
      <div>
        { errorMessage }
        {strongPass}
        { localStorage.getItem('token') === null ? props.history.push('/login')
        :
            <Spinner spinning={props.loading}>
                <Row className="layout_padding">
                <Col xs={{ span: 0 }} md={{ span: 8 }} lg={{ span: 8 }}></Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                    <h2 className="title">{t("changePassword")}</h2>
                    <Form name="change_password" className="login-form" onFinish={onFinish}>
                        <Form.Item name="old_pass"
                            rules={[
                            {
                                required: true,
                                message: t("passwordValMsg"),
                            },
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder={t("currentPass")} />
                        </Form.Item>
                        <Form.Item name="password"
                        rules={[
                            {
                            required: true,
                            message: t("passwordValMsg"),
                            },
                        ]}
                        hasFeedback
                        >
                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder={t("password")} />
                        </Form.Item>

                        <Form.Item name="confirm" dependencies={['password']} hasFeedback
                        rules={[
                            {
                            required: true,
                            message: t("confirmPasswordValMsg"),
                            },
                            ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                                }
                                return Promise.reject(t("passNoMatch"));
                            },
                            }),
                        ]}
                        >
                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder={t("confirmPassword")} />
                        </Form.Item>

                        <Form.Item className="text_center">
                            <Button type="primary" htmlType="submit" className="login-form-button">
                            {t("change")}
                            </Button>  
                        </Form.Item>
                    </Form>
                </Col>
                <Col xs={{ span: 0 }} md={{ span: 8 }} lg={{ span: 8 }}></Col>
              </Row>
            </Spinner>
        }
        </div>
  );
};

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error,
        token: state.token,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changePass: (old_pass, password) => dispatch(actions.changePass(old_pass, password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePass);