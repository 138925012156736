import React from 'react';
import {connect} from 'react-redux';
import { Layout, Menu, Row, Col } from 'antd';
import Icon, { MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as actions from '../store/actions/auth';
import '../css/layout.css';
import Terms from '../components/terms';
import LangSelect from '../components/langSelect';
import { useTranslation } from "react-i18next";
import "../i18n";

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

const CustomLayout = (props) => {
  const { t } = useTranslation();
    return (
      <Layout>
        <Header className="headerCont">
          <Row>
            <Col xs={{ span: 0 }} md={{ span: 4 }} lg={{ span: 4 }}>
              <div className="logo" ><a href="/"><img src={process.env.PUBLIC_URL + '/img/logo.png'} alt=""/></a></div></Col>
            <Col xs={{ span: 4 }} md={{ span: 16 }} lg={{ span: 16 }}>
              <Menu className="menu" mode="horizontal" defaultSelectedKeys={['1']}>
                <Menu.Item key="1">
                  <Link to="/">{t("home")}</Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <a href='/#services'>{t("services")}</a>
                </Menu.Item>
                <Menu.Item key="3">
                  <a href='/#we'>{t("we")}</a>
                </Menu.Item>
                <SubMenu title={t("search")}>
                  <Menu.Item key="5">
                    <Link to="/search">{t("simple")}</Link>
                  </Menu.Item>
                  <Menu.Item key="6">
                    <Link to="/searchAdvanced">{t("advanced")}</Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="7">
                  <Link to="/statistics">{t("statistics")}</Link>
                </Menu.Item> 
                <SubMenu title={t("scoring")}>
                  <Menu.Item key="11">
                    <Link to="/scoring">{t("single")}</Link>
                  </Menu.Item>
                  <Menu.Item key="12">
                    <Link to="/match">{t("matched")}</Link>
                  </Menu.Item>
                </SubMenu> 
                <Menu.Item key="13">
                    <Link to="/studs">{t("activeStuds")}</Link>
                  </Menu.Item>
                <Menu.Item key="14">
                    <Link to="/names">{t("nameseeker")}</Link>
                  </Menu.Item>
                {/* <Menu.Item key="15">
                  <a href='/#articles'>{t("articles")}</a>
                </Menu.Item> */}
              </Menu>
            </Col>
            <Col xs={{ span: 16 }} md={{ span: 0 }} lg={{ span: 0 }}>
              <div className="logo" ><a href="/"><img src={process.env.PUBLIC_URL + '/img/logo.png'} alt="" /></a></div>
            </Col>
            <Col xs={{ span: 4 }} md={{ span: 3 }} lg={{ span: 3 }}>
              <Menu className="menu text-right" mode="horizontal" defaultSelectedKeys={['2']}>
                { 
                  localStorage.getItem('token') != null ? 
                  <SubMenu title={localStorage.getItem('first_name')}>
                    <Menu.Item key="11">
                      <Link to="/changePass">{t("changePassword")}</Link>
                    </Menu.Item>
                    <Menu.Item key="12">
                      <Link to="/" onClick={props.logout}>{t("logOut")}</Link>
                    </Menu.Item>
                  </SubMenu>
                  :
                    <Menu.Item key="13">
                      <Link to="/login">{t("logIn")}</Link>
                    </Menu.Item>
                }
                  <LangSelect />
              </Menu>
            </Col>
            <Col xs={{ span: 0 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
          </Row>
        </Header>
        {/* <Content className="site-layout" style={{ minHeight:'83vh', padding: '0 50px', marginTop: 64 }} { ...props }> */}
        <Content className="site-layout" style={{ minHeight:'83vh', marginTop: 64 }} { ...props }>
          <a href="https://www.paypal.com/donate/?hosted_button_id=3KUX2KP3PAF7L" className="btn-flotante">Donar</a>
          <div className="site-layout-background" style={{ minHeight: 380 }}> {props.children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center', color: '#2D0B0B' }}>
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
              <h2 style={{ color: '#2D0B0B' }}>{t("contactUs")}</h2>
              <a href="mailTo:vbitettim@gmail.com" style={{fontSize: '18px', color: '#2D0B0B'}}><MailOutlined /> vbitettim@gmail.com</a>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
              <Row>
                <Col span={12} className='footer_links'>
                  <a href='/#services'>{t("services")}</a><br />
                  <a href='/#we'>{t("we")}</a><br />
                  <a href='/search'>{t("search")}</a><br />
                </Col>
                <Col span={12} className='footer_links'>
                  {/* <a href='/#prices'>Prices</a><br /> */}
                  <a href='/searchAdvanced'>{t("advanced")}</a><br />
                  <a href='/statistics'>{t("statistics")}</a><br />
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
              <h2 style={{ color: '#2D0B0B' }}>{t("followUs")}</h2>
              <a href="https://twitter.com/Horseeker" className="social_icon" target="_blank" rel="noreferrer"><Icon component={() => (<img src={process.env.PUBLIC_URL + '/img/xtwitter.svg'} alt="" style={{ verticalAlign: 'unset' }}/>)}/></a>
              <a href="https://instagram.com/horseeker_com?igshid=7k4lobim4fqn" className="social_icon" target="_blank" rel="noreferrer"><Icon component={() => (<img src={process.env.PUBLIC_URL + '/img/instagram.svg'} alt="" style={{ verticalAlign: 'unset' }}/>)}/></a>
            </Col>
          </Row>
          <Terms />
          {t("dataDisclaimer")}<br />
          {t("infoDisclaimer")}<br />
          ©2021 {t("createdBy")} <a href="https://gbitcorp.com/" style={{color: '#2D0B0B', fontWeight: "bold"}} rel="noopener noreferrer" target="_blank">Gbitcorp</a>
        </Footer>
      </Layout>
    );
}

const mapDispatchToProps = dispatch => {
  return {
      logout: () => dispatch(actions.logout())
  }
}

export default connect(null, mapDispatchToProps)(CustomLayout);

